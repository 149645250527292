import $ from 'jquery';
import "slick-carousel";

$(document).ready(function(){

    // let slider = $('.value');
    // let controls = $('#slider-control');
    // let pause = $('.icon-pause');
    // let play = $('.icon-play');
    // let flag = 0;
    // let bar = $('#bar');
    // let slideDesc = $('.value__content-description');
    // let slideBtn = $('.value__content-button');
    // const slides = [];
    //
    // for(let i = 0; i < slider.length; i++){
    //     slides.push(slider[i].getAttribute('data-slide'));
    // }
    //
    $('[js-main-slider]').slick({
        arrows:true,
        pagination:true,
        dots:false,
        fade: true,
    });
    //
    //
    // //Odometer
    // let valuesContainer = $('.numbers-img');
    // console.log(valuesContainer);
    // let flagNumber = false;
    // let allOdometers = document.querySelectorAll('.odometer-number');
    //
    // function isScrolledIntoView(elem) {
    //     var docViewTop = $(window).scrollTop();
    //     var docViewBottom = docViewTop + $(window).height();
    //
    //     var elemTop = $(elem).offset().top;
    //     var elemBottom = elemTop + $(elem).height();
    //
    //     return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    // }
    //
    // if(isScrolledIntoView(valuesContainer) && !flagNumber){
    //     for(let i = 0; i < allOdometers.length; i++){
    //         let odometer = new Odometer({
    //             el: allOdometers[i],
    //             format: '( ddd)'
    //         });
    //         let number = allOdometers[i].getAttribute('data-value');
    //         number = number.replace(/\s/g, '');
    //         if(number.indexOf('M') > -1) {
    //             setTimeout(() => {
    //                 allOdometers[i].classList.add('letter');
    //             }, 2000);
    //         }
    //         allOdometers[i].innerHTML = number + 'M';
    //     }
    //     flagNumber = true;
    // }
    //
    // window.addEventListener('scroll', () => {
    //     if(valuesContainer.hasClass('aos-animate') && !flagNumber || window.matchMedia("(max-width:2400px)").matches && !flagNumber){
    //         for(let i = 0; i < allOdometers.length; i++){
    //             let odometer = new Odometer({
    //                 el: allOdometers[i],
    //                 format: '( ddd)'
    //             });
    //             let number = allOdometers[i].getAttribute('data-value');
    //             number = number.replace(/\s/g, '');
    //             if(number.indexOf('M') > -1) {
    //                 setTimeout(() => {
    //                     allOdometers[i].classList.add('letter');
    //                 }, 2000);
    //             }
    //             allOdometers[i].innerHTML = number;
    //         }
    //         flagNumber = true;
    //     }
    // });



});
